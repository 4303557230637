import Theme from '../../../styles/Theme';
import IconMessage from '../../atoms/Icon/IconMessage';
import { ContainerHelp } from './StyledNavigation';

type Params = {
  message: string;
}

export default function Help(Props: Params) {
  const { message } = Props;

  return (
    <ContainerHelp href="https://mapfrespain-newreleases.appiancloud.com/suite/sites/sgo/page/crear-solicitud" target="_blank">
      <IconMessage color={Theme.colorWhite} width="18px" height="18px" />
      <span>{message}</span>
    </ContainerHelp>
  );
}
